@font-face {
    font-family: 'ContentDetailsFont';
    src: url('../../../Assets/Fonts/SecularOne-Regular.ttf');
}

.Contact {
    position: fixed;
    bottom: 0%;
    left: 0;
    width: 100%;
    z-index: 10;
}

.iconRow {
    display: flex;
    background-color: #4CAF50;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
}

.iconContainer {
    font-family: 'ContentDetailsFont';
    text-align: center;
    flex: 1;
    color: white;
    position: relative;
}

.icon {
    position: relative;
    top: -20px; 
    background-color: white;
    border-radius: 30px;
    padding: 5px;
}

span {
    font-size: 18px;
}
