@font-face {
    font-family: 'header-font';
    src: url(../../../Assets/Fonts/RubikDistressed-Regular.ttf);
}

@font-face {
    font-family: 'ContentDetailsFont';
    src: url('../../../Assets/Fonts/SecularOne-Regular.ttf');
}

.Header>h1 {
    font-family: 'header-font';
    font-size: 70px;
    color: white;
}

.Header>img {
    width: 300px;
}

.Header>.CallToActionContainer {
    position: fixed;
    width: 100%;
    display: flex;
    border-radius: 10px;
    padding: 10px;
    background-color: #4CAF50;
}

.Header>.CallToActionContainer>span {
    margin: auto;
    color: white;
    font-family: 'ContentDetailsFont';
}

.Header>.CallToActionContainer>.HeaderPhoneIcon {
    color: white;
    margin-right: 10px;
}