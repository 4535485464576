html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    text-align: center;
    background-image: url('./Assets/Images/background-image.jpg');
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
}

a {
    color: #25D366;
}