.Projects {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.carousel-item img {
    width: 200px;
    height: 200px;
    border-radius: 50%;    
    object-fit: cover;    
    clip-path: circle(50%); 
    transition: transform 0.5s ease-in-out; 
}

.carousel-inner {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;  
}
